/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

body {
}


//@font-face {
//  font-family: 'Kalam';
//  font-style: normal;
//  font-weight: 400;
//  src: url('/assets/fonts/Kalam-Regular.ttf');
//  src: local(''),
//  url('/assets/fonts/Kalam-Regular.ttf') format('truetype');
//}

.slides-ios,
.slides-md {
  --bullet-background: var(--text-color-gray);
  --bullet-background-active: var(--ion-text-color);
}

.clickable {
  cursor: pointer;
}

// Teaser Styles
.teaser-container {
  display: flex;

  .teaser-inner-container {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .teaser-link {
      text-decoration: none;
      font-family: sans-serif;
      font-size: 86%;
      color: var(--text-color-gray);
      text-align: left;
      line-height: 1.3;

      .teaser-image {
        max-width: 100%;
        max-height: 225px;
        margin-bottom: 0.2rem;
      }

      .teaser-title {
        margin-top: 0.6rem;
        margin-bottom: 0.8rem;
        font-size: 1rem;
      }

      .teaser-text {
        .virtual-link {
          text-decoration: underline;
          white-space: nowrap;
        }
      }
    }
  }
}

a {
  color: var(--ion-text-color);
}

span.unit {
  margin-left: 0.1em;
  white-space: nowrap;
}


// Taboola Styles
.tbl-feed-frame-DIVIDER .tbl-feed-card {
  box-shadow: none !important;
  border-bottom: var(--trennlinie-dick);
}

.trc_rbox_container,
.tbl-feed-header {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.thumbs-feed-01-delta .syndicatedItem .video-title,
.thumbs-feed-01-delta .syndicatedItem .branding,
span.video-label.video-title,
span.video-label.video-description,
span.branding.composite-branding,
span.trc_rbox_header_span,
.thumbnails-m .logoDiv a span,
span.branding.video-branding-flex-cta-item,
span.video-label-box.trc-main-label span.branding {
  color: var(--text-color-gray) !important;
}


// Center-Grid
.center-grid {
  max-width: var(--max-width);
  --ion-grid-column-padding: 0.25rem;
}

// Boxen
.box-container {

  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;

  background-color: var(--box-background-color);
  border-radius: var(--box-border-radius);

  h4 {
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    margin-bottom: 0.6rem;
    padding-bottom: 0.5rem;
    border-bottom: var(--trennlinie-mittel);
    text-transform: uppercase;
  }

}

// iOS-Workaround für Wetterseite, Toolbar und Sticky-Place-Header
.ios {

  ion-toolbar {
    padding-top: 3rem !important;
    padding-bottom: 1rem !important;
  }

  .sticky-place-header {
    .sticky-place-header-inner-container {
      padding-top: 4rem !important;
      padding-bottom: 0.5rem !important;
    }
  }

}

// Weathermap
.leaflet-container .leaflet-control-attribution {
  background: rgba(255, 255, 255, 0.1);
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  border-radius: 4px;
  background-color: transparent;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.leaflet-container {

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    color: white;
    background-color: #3a3c3f;
    box-shadow: none;
  }

  .leaflet-bar a:hover,
  .leaflet-bar a:focus {
    background-color: #595959;
  }

  .legend-container {
    background-color: #3a3c3f;
    opacity: 80%;
    padding-top: 2px;
    padding-right: 4px;
    padding-bottom: 2px;
    padding-left: 4px;
    border-radius: 4px;
    line-height: normal;
  }
  .legend-row {
    display: flex;
    align-items: center;
  }
  .legend-color-col {
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    margin-right: 0.2em;
    transform: translateY(-1px);
  }
  .legend-label-col {
    display: inline-block;
    font-size: 90%;
  }

}

