// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  // Muss deaktiviert werden, damit auf iPhone die Schrift nicht zu groß wird.
  // Siehe: https://ionicframework.com/docs/updating/8-0#dynamic-font
  --ion-dynamic-font: initial;


  //--ion-background-color: #3a3a3a;
  --ion-background-color: #6c6c6c;
  --ion-background-color-rgb: 58, 58, 58;

  --background-color-transparent: rgba(58, 58, 58, 0.4);
  --background-color-transparenter: rgba(58, 58, 58, 0.2);
  //--default-background-url: url(/assets/images/weather-backgrounds/tag_wolkenlos.jpg);

  --ion-text-color: white;
  --ion-text-color-rgb: 255, 255, 255;

  --theme-color: #ffdd00;
  --theme-color-rgb: 255, 221, 0;

  --text-color-gray: #dfe0e1;
  --text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

  // Border
  --trennlinie-dick: 3px solid rgb(226, 226, 226, 0.1);
  --trennlinie-mittel: 2px solid rgb(226, 226, 226, 0.1);
  --trennlinie-duenn: 1px solid rgb(226, 226, 226, 0.1);

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  //--ion-color-warning: #ffce00;
  //--ion-color-warning-rgb: 255, 206, 0;
  //--ion-color-warning-contrast: #ffffff;
  //--ion-color-warning-contrast-rgb: 255, 255, 255;
  //--ion-color-warning-shade: #e0b500;
  //--ion-color-warning-tint: #ffd31a;
  --ion-color-warning: var(--theme-color);
  --ion-color-warning-rgb: var(--theme-color-rgb);
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #fff000;


  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.sc-ion-loading-ios-h,
.sc-ion-loading-md-h {
  --spinner-color: white;
}


:root {
  --yellow: #FFDD00;
  --max-width: 800px;

  // Startseite (Standard-Hintergrund)
  --startpage-background-color: var(--yellow);
  --startpage-background-image: url(/assets/images/backgrounds/start-background.webp);
  --startpage-background-position: center bottom;
  --startpage-background-size: cover;

  // Swiper
  --swiper-pagination-color: var(--ion-text-color);

  // Boxen
  --box-background-color: rgba(0, 0, 0, 0.1);
  --box-border-radius: 1rem;
}
